import React, { useState } from 'react'
import bannerImg from "../Images/monochrome-street-food-entertainment.jpg"
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material'
import img1 from "../Images/image1.png"
import img2 from "../Images/Ngo1.png"
import img3 from "../Images/Ngo2.png"
import img4 from "../Images/Ngo3.png"
import img5 from "../Images/Ngo4.png"
import img6 from "../Images/Ngo5.png"
import img7 from "../Images/Ngo6.png"
import img8 from "../Images/Ngo7.png"
import img9 from "../Images/Ngo8.png"
import img10 from "../Images/Ngo9.png"
import img11 from "../Images/Ngo10.png"
import img12 from "../Images/Ngo11.png"
import imgBg from "../Images/Subtract.png"
import imgBg2 from "../Images/Subtract (1).png"
import ClearIcon from '@mui/icons-material/Clear';

const Orgnisation = () => {
    const [open, setOpen] = useState(false);
    const [selectedService, setSelectedService] = useState('');
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')

    const handleSubmit = async () => {
        console.log(name, email, phone, selectedService, selectedPhoto, "---------------------Ok");
        const payLoad = {
            name, email, phone, selectedPhoto, selectedService
        }
        const result = await fetch('https://ngoapi.markletechandmedia.com/api/volunteer/register', {
            method: 'POST',
            headers: { 'content-type': 'application/json', "accept": "application/json" },
            body: JSON.stringify(payLoad)
        })
        result = await result.json()
        console.log(result);


    }

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleServiceChange = (event) => setSelectedService(event.target.value);
    const handleFileChange = (event) => {
        setSelectedPhoto(event.target.files[0]);

    };

    return (
        <>
            <Box sx={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                <Box sx={{ width: '100%' }}>
                    <img src={bannerImg} alt="background" style={{ width: '100%', height: 'auto', maxHeight: '550px' }} />
                </Box>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '30%',
                        transform: 'translate(-50%, -50%)',
                        color: 'white',
                        display: 'flex',
                        flexDirection: 'column',
                        fontSize: { xs: 24, sm: 30, md: 40, lg: 50 },
                    }}
                >
                    <span style={{ color: 'white', fontWeight: '700' }}>
                        Be the <span style={{ color: 'orange' }}>Reason</span>
                    </span>
                    someone
                    <span style={{ color: 'orange', fontWeight: '700' }}>
                        Smiles <span style={{ color: 'white' }}>Today</span>
                    </span>
                </Box>
            </Box>


            <Box
                sx={{
                    color: 'black',
                    textAlign: 'center',
                    fontSize: { xs: 24, sm: 30, md: 40, lg: 50 },
                    fontWeight: 'bold', marginTop: 2
                }}
            >
                <Box component="span" sx={{ display: 'block' }}>
                    Our
                    <span style={{ color: '#FFA600' }}> Volunteers {''}</span>
                    <br />
                </Box>
            </Box>
            <Box sx={{
                fontSize: { xs: 14, sm: 20, md: 22, lg: 25 }, fontWeight: '400', textAlign: 'center', display: 'flex',
                justifyContent: 'center', my: 1, mx: 5
            }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
            </Box>


            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                <Button
                    sx={{
                        fontSize: { xs: 14, sm: 18 }, p: 1.5,
                        color: 'black', bgcolor: '#FFA600', fontWeight: '500', display: 'flex', alignItems: 'center', width: { xs: 210, sm: 300 }
                    }}
                    variant="contained"
                    onClick={handleOpen}
                >
                    Become a volunteer
                </Button>
            </Box>

            {/* Modal for volunteer form */}
            <Modal open={open}>
                <Box sx={{
                    position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
                    width: { xs: 300, sm: 400 }, bgcolor: 'white', p: 4, borderRadius: 2, boxShadow: 24
                }}>
                    <Box onClick={handleClose} sx={{ display: 'flex', justifyContent: 'right', }}>
                        <ClearIcon sx={{ '&:hover': { bgcolor: '#eff1f1' }, }} />
                    </Box>
                    <Typography sx={{ display: 'flex', justifyContent: 'center', fontSize: { xs: 20, sm: 24 }, fontWeight: 'bold', mb: 1 }}>
                        Volunteer Form
                    </Typography>

                    <TextField required fullWidth label="Name" sx={{ mb: 2 }} value={name} onChange={(e) => setName(e.target.value)} />
                    <TextField required fullWidth label="Email" sx={{ mb: 2 }} value={email} onChange={(e) => setEmail(e.target.value)} />
                    <TextField required fullWidth label="Phone" sx={{ mb: 2 }} value={phone} onChange={(e) => setPhone(e.target.value)} />
                    <FormControl required fullWidth sx={{ mb: 2 }}>
                        <InputLabel id="service-label">Services</InputLabel>
                        <Select
                            labelId="service-label"
                            value={selectedService}
                            label="Service"
                            onChange={handleServiceChange}
                        >
                            <MenuItem value="Teaching">Teaching</MenuItem>
                            <MenuItem value="Fundraising">Fundraising</MenuItem>
                            <MenuItem value="Event Management">Event Management</MenuItem>
                            <MenuItem value="Community Outreach">Community Outreach</MenuItem>
                            <MenuItem value="Mentorship">Mentorship</MenuItem>
                        </Select>
                    </FormControl>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>

                        <input
                            style={{}}
                            type="file"
                            // hidden
                            onChange={handleFileChange}
                        />

                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'right', mt: 1 }}>
                        <Button onClick={handleSubmit}
                            sx={{
                                color: 'white',
                                bgcolor: 'orange',
                                fontWeight: '600', fontSize: 14,
                                '&:hover': { bgcolor: '#fca73f' },
                            }} >Submit</Button>
                    </Box>
                </Box>
            </Modal>


            <Grid container spacing={2} sx={{ px: 2, mb: 10 }}>
                {[img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12].map((img, index) => (
                    <Grid item xs={6} sm={6} md={4} lg={3} key={index}>
                        <Box sx={{
                            height: { xs: 360, sm: 350, md: 400, lg: 440 }, // Adjust height based on screen size
                            alignItems: 'center', justifyContent: 'center',
                            display: 'flex', position: 'relative',
                        }}>
                            <Box
                                component="img"
                                src={imgBg2}
                                alt='background'
                                sx={{
                                    position: 'absolute',
                                    right: { xs: -5, sm: 40, md: 50 }, // Adjust right position based on screen size
                                    top: { xs: 85, sm: 70, md: 90 },   // Adjust top position based on screen size
                                    zIndex: 1,
                                    color: '#FFA600',
                                }}
                            />
                            <Box sx={{ borderRadius: 100, zIndex: 2 }}>
                                <Box
                                    component="img"
                                    src={img}
                                    alt='volunteer'
                                    sx={{
                                        width: '100%',
                                        height: 'auto',
                                    }}
                                />
                            </Box>
                            <Box
                                component="img"
                                src={imgBg}
                                alt='background'
                                sx={{
                                    position: 'absolute',
                                    left: { xs: -5, sm: 30, md: 50 },   // Adjust left position based on screen size
                                    bottom: { xs: 85, sm: 70, md: 90 }, // Adjust bottom position based on screen size
                                    zIndex: 1,
                                }}
                            />
                            <Box
                                sx={{
                                    position: 'absolute', bottom: 0, width: '100%', display: 'flex', flexDirection: 'column',
                                    alignItems: 'center', justifyContent: 'flex-end', zIndex: 3,
                                }}
                            >
                                <Box sx={{ fontSize: { xs: 20, sm: 25, md: 30 }, fontWeight: 700, color: 'black' }}>Name</Box>
                                <Box sx={{ fontSize: { xs: 12, sm: 14, md: 16 } }}>Position</Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 1 }}>
                                    <Button sx={{
                                        fontSize: { xs: 10, sm: 11, md: 12 },
                                        color: 'black', bgcolor: '#FFA600', fontWeight: '400', display: 'flex', alignItems: 'center',
                                    }} variant="contained">View Profile</Button>
                                </Box>
                            </Box>
                        </Box>

                    </Grid>
                ))}
            </Grid>


        </>
    )
}

export default Orgnisation
