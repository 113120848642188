import { Box, Grid, Button } from '@mui/material';
import React from 'react';
import img1 from "../Images/village-poor-people-wages_786429-5815.jpg";
import img2 from "../Images/pay_methods_branding 1.png";

const DonateUs = () => {
  return (
    <Box sx={{ mt: 10, mb: 10, mx: { xs: 2, sm: 3, md: 10 }, }}>
      <Grid container spacing={4} justifyContent="center">
        {/* Left Section */}
        <Grid item xs={12} md={6}>
          <Box>
            <Box sx={{ fontSize: { xs: 40, md: 60 }, fontWeight: 600, mb: 2 }}>
              DONATE FOR A <br /> GOOD CAUSE
            </Box>
            <img
              src={img1}
              alt="Background"
              style={{ width: '100%', maxWidth: 542, height: 'auto', marginTop: 10, borderRadius: 22 }}
            />
            <Box sx={{ mt: 5, textAlign: 'justify', fontSize: { xs: 16, md: 20 }, color: 'black' }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </Box>
          </Box>
        </Grid>

        {/* Right Section */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              p: 3,
              mt: { xs: 5, md: 0 },
              backgroundColor: 'white',
              borderRadius: 2,
              boxShadow: 3,
              border: '1px solid rgba(0, 0, 0, 0.1)',
            }}
          >
            <form>
              <Box sx={{ textAlign: 'center', fontSize: { xs: 25, md: 45 }, fontWeight: 600, mb: 3 }}>
                Payment Details
              </Box>

              {['Amount', 'Email', 'Phone', 'Name'].map((label, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                  <Box sx={{ fontSize: {xs:16,sm:20}, fontWeight: '600', }}>
                    {label}
                  </Box>
                  <input
                    type="text"
                    placeholder={`Enter ${label}`}
                    name={label.toLowerCase()}
                    required
                    style={{
                      width: '65%',
                      height: 48,
                      paddingLeft: 5,
                      borderRadius: 4,
                      border: '1px solid #ccc',fontSize: {xs:16,sm:20},
                    }}
                  />
                </Box>
              ))}

              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, mb: 2 }}>
                <img src={img2} alt="Payment Methods" style={{ maxWidth: '100%', height: 'auto' }} />
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Button sx={{
                  color: 'white',
                  bgcolor: '#FFA600',
                  fontWeight: 'bold',
                  px: 4,
                  '&:hover': { bgcolor: '#FF8C00' },
                }} variant="contained">Pay ₹0.00</Button>
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default DonateUs;
