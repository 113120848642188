import React from 'react';
import { Box, Grid, Button, Typography } from '@mui/material';
import bannerImg from '../Images/banner3.png';
import women from '../Images/woman 1.png';
import capacity from '../Images/capacity.png';
import health from '../Images/healthcare (1).png';
import mega from '../Images/megaphone 5.png';
import Enviroment from '../Images/seeding 1.png';
import Dm from '../Images/emergency-response.png';
import institution from '../Images/institution.png';
import child from '../Images/kids.png';
import edu from '../Images/open-book.png';

const WorkingArea = () => {
  return (
    <>
      <Box sx={{ position: 'relative', display: 'inline-block', width: '100%' }}>
        <Box sx={{ width: '100%' }}>
          <img src={bannerImg} alt="background" style={{ width: '100%', height: 'auto', maxHeight: '550px' }} />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: { xs: '30%', lg: '20%' },
            transform: 'translate(-50%, -50%)',
            color: 'orange',
            display: 'flex',
            flexDirection: 'column',
            fontSize: { xs: 24, sm: 30, md: 40, lg: 50 },
          }}
        >
          <span style={{ fontWeight: '700' }}>
            Together <span style={{ color: 'white' }}>We <br />Can Create A </span>
          </span>
          <span style={{ color: 'orange', fontWeight: '700' }}>
            Better World
          </span>
        </Box>
      </Box>

      <Box
        align="center"
        sx={{
          fontSize: { xs: 24, sm: 30, md: 40, lg: 50 },
          color: 'black',
          fontWeight: 'bold',
          my: 5
        }}
      >
        Working <Box component="span" sx={{ color: '#FFA600' }}>Areas</Box>
      </Box>

      <Grid container spacing={2} sx={{ px: { xs: 2, md: 10 }, my: 5 }}>
        {[
          { img: women, text: 'Women Empowerment' },
          { img: capacity, text: 'Capacity Building' },
          { img: health, text: 'Health' },
          { img: mega, text: 'Awareness' },
          { img: Enviroment, text: 'Environment' },
          { img: Dm, text: 'Disaster Management' },
          { img: institution, text: 'Institution' },
          { img: child, text: 'Child Issues' },
          { img: edu, text: 'Education' },
        ].map((item, index) => (
          <Grid item xs={6} sm={6} md={4} key={index}>
            <Button
              sx={{
                fontSize: { xs: 14, md: 18 },
                gap: 1,
                color: 'black',
                bgcolor: '#FFA600',
                display: 'flex',
                alignItems: 'center',
                p: 1,
                borderRadius: 2,
                width: '100%',
                justifyContent: 'center',
                textAlign: 'center',
                fontWeight: '600'
              }}
            >
              <img src={item.img} alt={item.text} style={{ height: 40, width: 40, }} />
              {item.text}
            </Button>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ mx: { xs: 3, md: 10 }, fontSize: { xs: 18, md: 24 }, fontWeight: '700', my: 8 }}>
        <Typography variant="h4" gutterBottom>
          Lorem Ipsum is simply dummy text
        </Typography>
        <Typography variant="body1" sx={{ fontSize: { xs: 14, md: 18 }, textAlign: 'justify', fontWeight: '400' }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
          Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </Typography>
      </Box>
    </>
  );
};

export default WorkingArea;
