import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Link, Grid, Paper } from '@mui/material';
import ngoLogo from '../Images/NgoLogo.png'; // Assuming you're using the same logo

const LoginSignup = () => {
  const [isSignup, setIsSignup] = useState(false);
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [confirmPass, setConfirmPass] = useState('')
  const [password, setPassword] = useState('')

  const toggleAuthMode = () => {
    setIsSignup(!isSignup);
  };

  // const handleSubmit = async () => {
  //   console.log(name, email, password, confirmPass, "---------------------Ok");
  //   const payLoad = {
  //     name, email, phone, selectedPhoto, selectedService
  //   }
  //   const result = await fetch('https://ngoapi.markletechandmedia.com/', {
  //     method: 'POST',
  //     headers: { 'content-type': 'application/json', "accept": "application/json" },
  //     body: JSON.stringify(payLoad)
  //   })
  //   result = await result.json()
  //   console.log(result);


  // }
  return (
    <Grid container component="main" sx={{ display: 'flex', justifyContent: 'center', my: { xs: 0, sm: 5 }, }}>
      <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} sx={{ borderRadius: 5 }} >
        <Box
          sx={{
            my: 5,
            mx: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={ngoLogo} alt="NGO Logo" style={{ maxHeight: 100 }} />
          <Typography component="h1" variant="h5" sx={{ mt: 2 }}>
            {isSignup ? 'Sign Up' : 'Login'}
          </Typography>

          <Box component="form" noValidate sx={{ mt: 1 }}>
            {isSignup && (
              <TextField margin="normal" required fullWidth label="Full Name" name="fullName"
                autoComplete="name" autoFocus
              />
            )}
            <TextField margin="normal" required fullWidth label="Email Address" name="email"
              autoComplete="email" autoFocus={!isSignup}
            />
            <TextField margin="normal" required fullWidth name="password" label="Password" type="password"
              autoComplete="current-password"
            />
            {isSignup && (
              <TextField margin="normal" required fullWidth name="confirmPassword" label="Confirm Password" type="password"
                autoComplete="new-password"
              />
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
            >
              {isSignup ? 'Sign Up' : 'Login'}
            </Button>

            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="#" variant="body2" onClick={toggleAuthMode}>
                  {isSignup ? 'Already have an account? Login' : "Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default LoginSignup;
