import React from 'react';
import { AppBar, Box, Button, Drawer, IconButton, List, ListItem, ListItemText, Menu, MenuItem, Toolbar, useMediaQuery } from '@mui/material';
import ngoLogo from "../Images/NgoLogo.png";
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Link } from 'react-router-dom';

function Header() {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const drawer = (
    <Drawer
      anchor="left"
      open={drawerOpen}
      onClose={handleDrawerToggle}
    >
      <List sx={{ width: 200 }}>
        <ListItem component={Link} to="/Home">
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem component={Link} to="/Orgnisation">
          <ListItemText primary="Organisation" />
        </ListItem>
        <ListItem component={Link} to="/WorkingArea">
          <ListItemText primary="Working Area" />
        </ListItem>
        <ListItem component={Link} to="/Event">
          <ListItemText primary="Event" />
        </ListItem>
        <ListItem component={Link} to="/DonateUs">
          <ListItemText primary="Donate Us" />
        </ListItem>
        <ListItem component={Link} to="/Contact">
          <ListItemText primary="Contact Us" />
        </ListItem>
      </List>
    </Drawer>
  );

  return (
    <>
      <AppBar position="sticky">
        <Toolbar sx={{
          bgcolor: 'white',
          justifyContent: 'space-between'
        }}>
          {/* IconButton for Logo */}
          <Link to={'/Home'}>
            <IconButton
              size="large"
              edge="end"
              color="primary"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <img src={ngoLogo} alt="NGO Logo" style={{ maxHeight: 50 }} />
            </IconButton>
          </Link>

          {isMobile ? (
            <Box sx={{ display: 'flex', justifyContent: 'right' }}>
              <IconButton
                edge="start"
                color="primary"
                aria-label="menu"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
              {drawer}
              <Button color="primary" onClick={handleMenuClick}>
                <AccountCircleIcon color="primary" sx={{ height: 40, width: 40 }} />
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <Link to={"/LoginSignup"}> <MenuItem >Login/ Signup</MenuItem></Link>
                <MenuItem onClick={handleMenuClose}>Edit Profile</MenuItem>
                <MenuItem onClick={handleMenuClose}>Log out</MenuItem>
              </Menu>
            </Box>
          ) : (
            <Box sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: { xs: 'center', md: 'right' },
              flexGrow: 1,
            }}>
              <Link to={"/Home"}>
                <Button color="primary" sx={{ m: 1 }}>Home</Button>
              </Link>
              <Link to={"/Orgnisation"}>
                <Button color="primary" sx={{ m: 1 }}>Organisation</Button>
              </Link>
              <Link to={"/WorkingArea"}>
                <Button color="primary" sx={{ m: 1 }}>Working Area</Button>
              </Link>
              <Link to={"/Event"}>
                <Button color="primary" sx={{ m: 1 }}>Event</Button>
              </Link>
              <Link to={"/DonateUs"}>
                <Button color="primary" sx={{ m: 1 }}>Donate Us</Button>
              </Link>
              <Link to={"/Contact"}>
                <Button color="primary" sx={{ m: 1 }}>Contact Us</Button>
              </Link>
              <Box sx={{ paddingLeft: 5, marginRight: 5 }}>
                <Button color="primary" onClick={handleMenuClick}>
                  <AccountCircleIcon color="primary" sx={{ height: 40, width: 40 }} />
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <Link to={"/LoginSignup"}> <MenuItem onClick={handleMenuClose}>Login/ Signup</MenuItem></Link>
                  <MenuItem onClick={handleMenuClose}>Edit Profile</MenuItem>
                  <MenuItem onClick={handleMenuClose}>Log out</MenuItem>
                </Menu>
              </Box>
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
}

export default Header;
