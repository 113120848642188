import React from 'react'
import { Box } from '@mui/system'
import img22 from "../Images/linkdin.png"
import ngoLogo from "../Images/NgoLogo.png";
import img21 from "../Images/insta.png"
import img23 from "../Images/twitter.png"
import img24 from "../Images/facebook.png"

const Footer = () => {
    return (
        <Box sx={{ width: '100%', bgcolor: 'black', }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', mx: { xs: 3, sm: 10 }, alignItems: 'center', gap: 1 }} >
                <Box sx={{ my: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
                    <img src={ngoLogo} alt="Background" style={{ backgroundColor: 'white', height: 80, width: 80, padding: 5, borderRadius: 10, }} />
                    <Box sx={{ color: 'white', display: 'flex', fontSize: { xs: 12, sm: 18 }, width: 200, textAlign: 'justify', }}>Lorem Ipsum is simply dummy text of the
                        printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took</Box>
                </Box>
                <Box sx={{ color: 'orange', fontWeight: '500', fontSize: { xs: 14, sm: 22 }, }}>
                    Quick Links
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginTop: 2 }}>
                        <Box sx={{ backgroundColor: 'white', width: 5, height: 5, borderRadius: 10, }} />
                        <Box sx={{ color: 'white', fontSize: { xs: 12, sm: 18 } }}>About Us</Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginTop: 1 }}>
                        <Box sx={{ backgroundColor: 'white', width: 5, height: 5, borderRadius: 10, }} />
                        <Box sx={{ color: 'white', fontSize: { xs: 12, sm: 18 } }}>Services</Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginTop: 1 }}>
                        <Box sx={{ backgroundColor: 'white', width: 5, height: 5, borderRadius: 10, }} />
                        <Box sx={{ color: 'white', fontSize: { xs: 12, sm: 18 } }}>Projects</Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginTop: 1 }}>
                        <Box sx={{ backgroundColor: 'white', width: 5, height: 5, borderRadius: 10, }} />
                        <Box sx={{ color: 'white', fontSize: { xs: 12, sm: 18 } }}>News</Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginTop: 1 }}>
                        <Box sx={{ backgroundColor: 'white', width: 5, height: 5, borderRadius: 10, }} />
                        <Box sx={{ color: 'white', fontSize: { xs: 12, sm: 18 } }}>Career</Box>
                    </Box>
                </Box>
                <Box sx={{ color: 'orange', fontWeight: '500', fontSize: { xs: 14, sm: 22 }, }}>
                    Support
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginTop: 2 }}>
                        <Box sx={{ backgroundColor: 'white', width: 5, height: 5, borderRadius: 10, }} />
                        <Box sx={{ color: 'white', fontSize: { xs: 12, sm: 18 } }}>Help & FAQ</Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginTop: 1 }}>
                        <Box sx={{ backgroundColor: 'white', width: 5, height: 5, borderRadius: 10, }} />
                        <Box sx={{ color: 'white', fontSize: { xs: 12, sm: 18 } }}>Causes</Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginTop: 1 }}>
                        <Box sx={{ backgroundColor: 'white', width: 5, height: 5, borderRadius: 10, }} />
                        <Box sx={{ color: 'white', fontSize: { xs: 12, sm: 18 } }}>Events</Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginTop: 1 }}>
                        <Box sx={{ backgroundColor: 'white', width: 5, height: 5, borderRadius: 10, }} />
                        <Box sx={{ color: 'white', fontSize: { xs: 12, sm: 18 } }}>Contact Us
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginTop: 1 }}>
                        <Box sx={{ backgroundColor: 'white', width: 5, height: 5, borderRadius: 10, }} />
                        <Box sx={{ color: 'white', fontSize: { xs: 12, sm: 18 } }}>Terms-Service</Box>
                    </Box>
                </Box>
                <Box sx={{ color: 'orange', fontWeight: '500', fontSize: { xs: 14, sm: 22 }, }}>
                    Latest Tweets
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginTop: 2 }}>
                        <Box sx={{ backgroundColor: 'white', width: 5, height: 5, borderRadius: 10, }} />
                        <Box sx={{ color: 'white', fontSize: { xs: 12, sm: 18 } }}>Lorem Ipsum</Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginTop: 1 }}>
                        <Box sx={{ backgroundColor: 'white', width: 5, height: 5, borderRadius: 10, }} />
                        <Box sx={{ color: 'white', fontSize: { xs: 12, sm: 18 } }}>Lorem Ipsum</Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginTop: 1 }}>
                        <Box sx={{ backgroundColor: 'white', width: 5, height: 5, borderRadius: 10, }} />
                        <Box sx={{ color: 'white', fontSize: { xs: 12, sm: 18 } }}>Lorem Ipsum</Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginTop: 1 }}>
                        <Box sx={{ backgroundColor: 'white', width: 5, height: 5, borderRadius: 10, }} />
                        <Box sx={{ color: 'white', fontSize: { xs: 12, sm: 18 } }}>Lorem Ipsum</Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginTop: 1 }}>
                        <Box sx={{ backgroundColor: 'white', width: 5, height: 5, borderRadius: 10, }} />
                        <Box sx={{ color: 'white', fontSize: { xs: 12, sm: 18 } }}>Lorem Ipsum</Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ color: 'white', textAlign: 'center', justifyContent: 'center', display: 'flex', alignItems: 'center', mt: 1 }}>
                <Box sx={{ borderStyle: 'ridge', borderWidth: .4, width: 197, height: 40, borderRadius: 40, alignItems: 'center', justifyContent: 'center', display: 'flex', gap: 2 }}>
                    <img src={img21} height={18} width={18} alt="Background" />
                    <img src={img22} height={18} width={18} alt="Background" />
                    <img src={img23} height={18} width={18} alt="Background" />
                    <img src={img24} height={18} width={18} alt="Background" />
                </Box>
            </Box>
            <Box sx={{ bgcolor: 'white', textAlign: 'center', padding: 1, marginTop: 1, fontSize: { xs: 10, sm: 16 }, fontWeight: '400' }}>
                {`Copyright © 2024 global care | Powered by Markletechandmedia.com`}
            </Box>
        </Box>
    )
}

export default Footer