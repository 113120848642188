import React from 'react'
import { Box, } from '@mui/material'
import bannerImg from "../Images/bann.jpg";


export const Banner = () => {
    return (
        <>
            <Box sx={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                <Box sx={{ width: '100%' }}>
                    <img src={bannerImg} alt="background" style={{ width: '100%', height: 'auto', maxHeight: 450 }} />
                </Box>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '25%',
                        transform: 'translate(-50%, -50%)',
                        display: 'flex',
                        flexDirection: 'column',
                        fontSize: { xs: 18, sm: 30, md: 40, lg: 50 },
                        px: { xs: 2, sm: 3, md: 4 },
                    }}
                >
                    <span style={{ color: 'orange', fontWeight: '700' }}>
                        Share Your <br />
                        Love To <span style={{ color: 'white', fontWeight: '400' }}>Make <br /> Someone's</span>
                        <span style={{ color: 'white', fontWeight: '400' }}>
                            <br /> Life Better
                        </span>
                    </span>
                </Box>
            </Box>

        </>
    )
}
