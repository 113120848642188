import React, { useState } from 'react';
import { Box, Button, Grid, Typography, TextField } from '@mui/material';
import bannerImg from '../Images/09cli-trees1-videoSixteenByNineJumbo1600.jpg';

const ContactUs = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')


    const handleSubmit = async () => {
        console.log(name, email, phone, message, "---------------------Ok");
        const payLoad = {
            name, email, phone, message
        }
        const result = await fetch('https://ngoapi.markletechandmedia.com/api/volunteer/register', {
            method: 'POST',
            headers: { 'content-type': 'application/json', "accept": "application/json" },
            body: JSON.stringify(payLoad)
        })
        result = await result.json()
        localStorage.setItem("user-info", JSON.stringify(result))
        // console.log(result);


    }
    return (
        <>
            {/* Banner Section */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    height: { xs: 'auto', md: 500 },
                    position: 'relative',
                    overflow: 'hidden',
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        bgcolor: 'rgba(0, 0, 0, 0.4)',
                        zIndex: 1,
                    }}
                />
                <Typography
                    sx={{
                        position: 'absolute',
                        bottom: '50%',
                        left: '50%',
                        transform: 'translate(-50%, 0)',
                        color: 'orange',
                        fontWeight: 700,
                        fontSize: { xs: '5vw', md: '3vw' },
                        textAlign: 'center',
                        zIndex: 2,
                    }}
                >
                    Contact Us
                </Typography>
                <img
                    src={bannerImg}
                    alt="Banner"
                    style={{ width: '100%', objectFit: 'cover', height: '100%' }}
                />
            </Box>

            {/* Contact Form Section */}
            <Grid container spacing={2} sx={{ px: { xs: 2, md: 10 }, my: { xs: 3, md: 5 }, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                <Grid item xs={11} md={5} >
                    <Typography sx={{ fontSize: { xs: 20, sm: 25 }, fontFamily: 'sans-serif', fontWeight: 'bold', display: 'flex', justifyContent: 'center' }} gutterBottom>
                        Get in Touch With Us
                    </Typography>
                    <Box
                        sx={{
                            backgroundColor: 'black',
                            p: 3,
                            borderRadius: 5,
                            color: 'white',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <form style={{ width: '100%' }}>
                            <TextField
                                placeholder={'Name'}
                                fullWidth
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                margin="normal"
                                InputProps={{
                                    style: {
                                        color: 'white',
                                        borderColor: 'white',
                                        borderRadius: 10,
                                        backgroundColor: 'black',
                                    },
                                }}
                                InputLabelProps={{ style: { color: 'white' } }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'white',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'white',
                                        },
                                    },
                                }}
                            />
                            <TextField
                                placeholder={'Ph. Number'}
                                fullWidth
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                margin="normal"
                                InputProps={{
                                    style: {
                                        color: 'white',
                                        borderColor: 'white',
                                        borderRadius: 10,
                                        backgroundColor: 'black',
                                    },
                                }}
                                InputLabelProps={{ style: { color: 'white' } }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'white',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'white',
                                        },
                                    },
                                }}
                            />
                            <TextField
                                placeholder={'Email ID'}
                                fullWidth
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                margin="normal"
                                InputProps={{
                                    style: {
                                        color: 'white',
                                        borderColor: 'white',
                                        borderRadius: 10,
                                        backgroundColor: 'black',
                                    },
                                }}
                                InputLabelProps={{ style: { color: 'white' } }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'white',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'white',
                                        },
                                    },
                                }}
                            />
                            <TextField
                                placeholder="Your message here..."
                                multiline
                                rows={3}
                                fullWidth
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                margin="normal"
                                InputProps={{
                                    style: {
                                        color: 'white',
                                        borderColor: 'white',
                                        borderRadius: 10,
                                        backgroundColor: 'black',
                                    },
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'white',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'white',
                                        },
                                    },
                                }}
                            />
                            <Button
                                onClick={handleSubmit}
                                variant="contained"
                                sx={{
                                    mt: 1,
                                    bgcolor: '#FFA600',
                                    color: 'white',
                                    fontWeight: 'bold',
                                    width: '100%',
                                    fontSize: { xs: 12, sm: 15 },
                                    '&:hover': {
                                        bgcolor: '#FF8C00',
                                    },
                                }}
                            >
                                Submit
                            </Button>
                        </form>
                    </Box>
                </Grid>

                {/* Contact Information Section */}
                <Grid item xs={12} md={6} sx={{ alignItems: 'center', display: 'flex', mb: 5 }}>
                    <Box sx={{ mt: { xs: 1, md: 0 }, ml: { xs: 2, md: 6 }, alignItems: 'center', justifyContent: 'center' }}>
                        <Typography sx={{ fontSize: { xs: 20, sm: 25 }, fontWeight: 'bold', textDecorationLine: 'underline' }} gutterBottom>
                            Contact Us
                        </Typography>
                        <Typography sx={{ fontSize: { xs: 16, sm: 20 }, fontWeight: '600' }}>
                            info@markletechandmedia.com
                        </Typography>
                        <Typography sx={{ fontSize: { xs: 16, sm: 20 }, fontWeight: '600' }}>
                            markletechandmedia@gmail.com
                        </Typography>
                        <Typography sx={{ fontSize: { xs: 16, sm: 20 }, fontWeight: 'bold' }}>
                            +91-798-233-2070 / +91-767-810-2159
                        </Typography>
                        <Typography sx={{ fontSize: { xs: 16, sm: 20 }, fontWeight: 'bold' }}>
                            A-52, Som Bazar Chowk,
                            <br />
                            Vikas Nagar, Uttam Nagar, New Delhi-59 (INDIA)
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default ContactUs;
