import React from 'react'
import { Box, keyframes, } from '@mui/system'
import img6 from "../Images/ph_5067_93068.jpg"
import bannerImg from "../Images/banner1.jpg"
import { Grid } from '@mui/material'

const Event = () => {
  const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`
  return (
    <>
      <Box sx={{ position: 'relative', display: 'inline-block', width: '100%' }}>
        <Box sx={{ width: '100%' }}>
          <img src={bannerImg} alt="background" style={{ width: '100%', height: 'auto', maxHeight: 450 }} />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '25%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            flexDirection: 'column',
            fontSize: { xs: 18, sm: 30, md: 40, lg: 50 },
            px: { xs: 2, sm: 3, md: 4 },
          }}
        >
          <span style={{ color: 'orange', fontWeight: '700' }}>
            Share Your <br />
            Love To <span style={{ color: 'white', fontWeight: '400' }}>Make <br /> Someone's</span>
            <span style={{ color: 'white', fontWeight: '400' }}>
              <br /> Life Better
            </span>
          </span>
        </Box>
      </Box>

      <Box
        align="center"
        sx={{
          fontSize: { xs: 24, sm: 30, md: 40, lg: 50 },
          color: 'black',
          fontWeight: 'bold',
          my: 5
        }}
      >
        Our <Box component="span" sx={{ color: 'orange' }}>Upcoming </Box>
        Events
      </Box>

      <Box
        sx={{
          px: 2, py: 3,
          animation: `${fadeIn} 1s ease-in`,
        }}
      >
        <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
          {Array(8).fill().map((_, index) => (
            <Grid item xs={6} sm={4} md={3} key={index}>
              <Box
                sx={{
                  borderWidth: { xs: 1, sm: 2 },
                  borderColor: 'black',
                  color: '#173A5E',
                  borderStyle: 'solid',
                  height: "100%",
                  width: '100%',
                  borderRadius: 5,
                  p: 2,
                  boxSizing: 'border-box',
                  mb: 2,
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                  },
                }}
              >
                <img
                  alt="Background"
                  src={img6}
                  style={{
                    borderStyle: 'solid',
                    borderWidth: 1,
                    borderColor: 'black',
                    borderRadius: 10,
                    width: '100%',
                    height: 'auto',
                    maxHeight: 200,
                    transition: 'transform 0.3s ease',
                  }}
                />
                <Box sx={{ fontSize: 17, fontWeight: '400', color: 'black', lineHeight: 2, mt: 2 }}>
                  #Food Camp
                </Box>
                <Box
                  sx={{
                    fontSize: { xs: 16, sm: 20 },
                    fontWeight: '700',
                    color: 'black',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  Run for the senior citizens.
                  <Box
                    sx={{
                      height: {xs:26,sm:30},
                      width: {xs:40,sm:70},
                      bgcolor: 'black',
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                      mr: 1,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: 'white',
                      fontSize: { xs: 10, sm: 12 },
                      p: 1,
                    }}
                  >
                    Aug 2024
                    <Box
                      sx={{
                        bgcolor: '#FFA600',
                        height: '100%',
                        width: '80%',
                        borderRadius: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: { xs: 12, sm: 18 },
                        fontWeight: '700',
                      }}
                    >
                      08
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ fontSize: { xs: 12, sm: 15 }, fontWeight: '400', color: 'black' }}>
                  Lorem Ipsum is simply dummy text <br /> of the printing and typesetting industry.
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

    </>
  )
}

export default Event