import React from 'react';
import { Box, Button, Grid, keyframes, Typography, useMediaQuery } from '@mui/material';
import img1 from "../Images/reading-book.png"
import img2 from "../Images/water.png"
import img3 from "../Images/surgery.png"
import img4 from "../Images/healthcare (1).png"
import img5 from "../Images/76638.jpg"
import img6 from "../Images/ph_5067_93068.jpg"
import img7 from "../Images/village-poor-people-wages_786429-5815.jpg"
import img8 from "../Images/megaphone.png"
import img9 from "../Images/hand-shake.png"
import img10 from "../Images/money (1).png"
import img11 from "../Images/volunteering.png"
import img12 from "../Images/charity-workers-giving-food-need.jpg"
import img13 from "../Images/community-support_870301-848.jpg"
import img14 from "../Images/community-support_870301-943.jpg"
import img15 from "../Images/group-volunteers-preparing-free-food-rations-poor-people-need-charity-workers-member_629685-30841.jpg"
import img16 from "../Images/group-volunteers-preparing-free-food-rations-poor-people-need-charity-workers-members_629685-30647.jpg"
import img17 from "../Images/help-volunteers-donate-free-food-those-need_114016-10653.jpg"
import img18 from "../Images/voluntary-people-offer-humanitar.jpg"
import img19 from "../Images/istockphoto-1316738100-612x612.jpg"
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { Banner } from './Banner';

const Home = () => {
    const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`
    const isMobile = useMediaQuery('(max-width:600px)');
    const gridColumns = isMobile ? 6 : 12;

    return (
        <>
            <Banner />
            {/* content 1 */}
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    mx: { xs: 3, sm: 10 },
                    my: { xs: 2, sm: 10 },
                    justifyContent: 'space-between',
                }}
            >
                {[
                    { imgSrc: img1, title: 'Children Education' },
                    { imgSrc: img2, title: 'Clean Mineral Water' },
                    { imgSrc: img3, title: 'Surgery & Treatment' },
                    { imgSrc: img4, title: 'Medical Aid' },
                ].map((item, index) => (
                    <Box
                        key={index}
                        sx={{
                            flexBasis: { xs: 'calc(50% - 16px)', sm: 'calc(25% - 16px)' },
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative',
                            overflow: 'hidden',
                        }}
                    >
                        <Box
                            sx={{
                                color: '#000000',
                                width: { xs: 50, md: 100 },
                                height: { xs: 50, md: 100 },
                                borderWidth: 1,
                                borderColor: 'black',
                                bgcolor: '#fff',
                                borderStyle: 'solid',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: '100%',
                                position: 'relative',
                                zIndex: 2,
                            }}
                        >
                            <img
                                src={item.imgSrc}
                                alt="Logo"
                                style={{
                                    height: 'auto',
                                    width: 'auto',
                                    maxHeight: '60%', // Adjust max height according to the container
                                    maxWidth: '60%', // Adjust max width according to the container
                                }}
                            />
                        </Box>

                        <Box
                            sx={{
                                borderWidth: 1,
                                color: '#173A5E',
                                borderStyle: 'solid',
                                height: { xs: 100, md: 200 },
                                width: { xs: 100, md: 200 },
                                borderRadius: 5,
                                position: 'relative',
                                top: -40,
                                zIndex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                padding: { xs: 3, md: 2 },
                            }}
                        >
                            <Box
                                sx={{
                                    marginTop: 4,
                                    fontSize: { xs: 11, md: 23 },
                                    fontWeight: '500',
                                    color: 'black',
                                    lineHeight: 1.5,
                                }}
                            >
                                Donate for
                            </Box>
                            <Box sx={{ fontSize: { xs: 15, md: 30 }, fontWeight: 'bold', color: 'black' }}>
                                {item.title}
                            </Box>
                            <Box sx={{ fontSize: { xs: 11, md: 23 }, marginTop: 1, fontWeight: '500', color: 'black' }}>
                                More Details
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Box>


            {/* content 2 */}
            <Box
                sx={{
                    color: 'black',
                    fontSize: { xs: 16, sm: 30, md: 40 },
                    fontWeight: 'bold',
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: { xs: 2, md: 5 },
                    alignItems: 'center',
                    textAlign: { xs: 'center', md: 'left' },
                    justifyContent: { xs: 'left', md: 'center' }
                }}
            >
                <Box
                    component="img"
                    src={img5}
                    alt="Background"
                    sx={{
                        height: { xs: '100%', md: 409 },
                        width: { xs: '85%', md: 500 },
                        borderColor: 'black',
                        borderWidth: 2,
                        borderStyle: 'solid',
                        borderRadius: 5,
                    }}
                />

                <Box
                    component="span"
                    sx={{
                        display: 'block',
                        fontWeight: '400',
                        width: { xs: '80%', md: 557 }, // Responsive width
                        textAlign: 'justify',
                    }}
                >
                    <Box
                        component="span"
                        sx={{
                            fontSize: { xs: 22, sm: 26, md: 30 },
                            fontWeight: 'bolder',
                            display: 'inline-block',
                            paddingBottom: { xs: 1, md: 0 }
                        }}
                    >
                        Welcome to Global Care
                    </Box>
                    <br />
                    <Box
                        component="span"
                        sx={{
                            fontSize: { xs: 18, sm: 20, md: 30 },
                            fontWeight: 'bold',
                            display: 'inline-block',
                            paddingBottom: { xs: .2, md: 0 }, wordSpacing: -1
                        }}
                    >
                        A world where poverty will not exist
                    </Box>
                    <br />
                    <Box
                        component="span"
                        sx={{
                            fontSize: { xs: 16, sm: 20, md: 26 },
                            display: 'inline-block',
                            paddingBottom: { xs: .5, md: 0 }, fontWeight: 'bold'
                        }}
                    >
                        We are the largest crowdfunding
                    </Box>
                    <br />
                    <Box
                        component="span"
                        sx={{
                            fontSize: { xs: 14, sm: 16, md: 18 },
                            display: 'block',
                        }}
                    >
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                        galley of type and scrambled it to make a type specimen book.
                    </Box>
                </Box>

            </Box>

            <Box
                sx={{
                    color: 'black',
                    textAlign: 'center',
                    fontSize: { xs: 25, sm: 30, md: 40 },
                    fontWeight: 'bold', my: 3
                }}
            >
                <Box component="span" sx={{ display: 'block' }}>
                    We Are Always Where
                </Box>

                <Box component="span" sx={{ display: 'block', }}>
                    Other <span style={{ color: '#FFA600' }}> People Need {''}</span>Help
                </Box>
            </Box>
            { }

            {/* content 3 */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'row', sm: 'row' },
                    flexWrap: 'wrap',
                    gap: 2,
                    px: { xs: 3, sm: 15 },
                    py: { xs: 3, sm: 5 },
                    justifyContent: 'space-between',
                }}
            >
                {['Helping Blind', 'Educating Children', 'Women Empowerment', 'Saving Environment'].map((text, index) => {
                    const [title, subtitle] = text.split(' ');

                    return (
                        <Box
                            key={index}
                            sx={{
                                borderWidth: 1,
                                borderStyle: 'solid',
                                height: { xs: 'auto', sm: 220 },
                                width: { xs: '47%', sm: 230 },
                                borderRadius: 5,
                                p: 2,
                                boxSizing: 'border-box',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: { xs: 18, md: 24 },
                                    fontWeight: 'bold',
                                    color: 'black',
                                }}
                            >
                                {title}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: { xs: 18, md: 24 },
                                    fontWeight: 'bold',
                                    color: 'black',
                                }}
                            >
                                {subtitle}
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography sx={{ fontSize: { xs: 12, sm: 14 }, fontWeight: '500', color: 'black' }}>
                                    Read More...
                                </Typography>
                                <ArrowOutwardIcon
                                    sx={{
                                        backgroundColor: '#FFA600',
                                        ml: { xs: 1, sm: 8 },
                                        height: { xs: 25, sm: 35, },
                                        width: { xs: 25, sm: 35, },
                                        borderRadius: '50%',
                                    }}
                                />
                            </Box>
                        </Box>
                    );
                })}
            </Box>



            {/* content 4 */}

            <Box
                sx={{
                    color: 'black',
                    textAlign: 'center',
                    fontSize: { xs: 25, sm: 30, md: 40 },
                    fontWeight: 'bold', my: 5
                }}
            >
                <Box component="span" sx={{ display: 'block' }}>
                    Join our <span style={{ color: '#FFA600' }}> upcoming {''}</span> events
                    <br />
                    for contribution
                </Box>
            </Box>

            {/* content 4 */}


            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    alignItems: 'center', px: 5,
                    gap: 2,
                    animation: `${fadeIn} 1s ease-in`,
                }}
            >
                {Array(3).fill().map((_, index) => (
                    <Box
                        key={index}
                        sx={{
                            borderWidth: { xs: 1, sm: 2 },
                            borderColor: 'black',
                            color: '#173A5E',
                            borderStyle: 'solid',
                            height: "100%",
                            width: { xs: '100%', sm: 382 },
                            borderRadius: 5,
                            p: 2,
                            boxSizing: 'border-box',
                            mb: 2,
                            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                            '&:hover': {
                                transform: 'scale(1.05)',
                                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                            },
                        }}
                    >
                        <img
                            alt="Background"
                            src={img6}
                            style={{
                                borderStyle: 'solid',
                                borderWidth: 1,
                                borderColor: 'black',
                                borderRadius: 10,
                                width: '100%',
                                height: 'auto',
                                maxHeight: 200,
                                transition: 'transform 0.3s ease',
                            }}
                        />
                        <Box sx={{ fontSize: 17, fontWeight: '400', color: 'black', lineHeight: 2, mt: 2 }}>
                            #Food Camp
                        </Box>
                        <Box
                            sx={{
                                fontSize: { xs: 16, sm: 20 },
                                fontWeight: '700',
                                color: 'black',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            Run for the senior <br /> citizens.
                            <Box
                                sx={{
                                    height: 30,
                                    width: 70,
                                    bgcolor: 'black',
                                    borderTopLeftRadius: 10,
                                    borderTopRightRadius: 10,
                                    mr: 1,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: 'white',
                                    fontSize: { xs: 10, sm: 12 },
                                    p: 1,
                                }}
                            >
                                Aug 2024
                                <Box
                                    sx={{
                                        bgcolor: '#FFA600',
                                        height: '100%',
                                        width: '80%',
                                        borderRadius: 2,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontSize: { xs: 12, sm: 18 },
                                        fontWeight: '700',
                                    }}
                                >
                                    08
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ fontSize: { xs: 12, sm: 15 }, fontWeight: '400', color: 'black', }}>
                            Lorem Ipsum is simply dummy text <br /> of the printing and typesetting industry.
                        </Box>
                    </Box>
                ))}
            </Box>

            {/* content 5 */}

            <Box
                sx={{
                    my: 5, position: 'relative', height: { xs: 400, sm: 500, md: 600 }, width: '100%',
                }} >
                <Box
                    sx={{
                        position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', bgcolor: 'rgba(0, 0, 0, 0.4)',
                    }}
                />
                <Box
                    sx={{
                        color: '#FFA600',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'center',
                        fontSize: { xs: 30, sm: 40, md: 50 },
                        fontWeight: '500',
                        width: { xs: '90%', sm: '80%', md: 700, lg: 900 },
                        px: 2,
                    }}
                >
                    We are here to stop poverty
                    <span style={{ fontWeight: '700', color: 'white' }}>
                        We are fundraising for the people who <br /> are fighting against poverty
                    </span>
                </Box>
                <Button
                    sx={{
                        position: 'absolute',
                        top: { xs: '75%', md: '80%' },
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: 'white',
                        bgcolor: '#FFA600',
                        fontWeight: 'bold',
                        px: { xs: 3, sm: 5 },
                        fontSize: { xs: '0.8rem', sm: '1', md: '1.2rem' },
                    }}
                    variant="contained"
                >
                    Donate Us
                </Button>
                <img
                    src={img7} alt="Background"
                    style={{
                        height: '100%', width: '100%', objectFit: 'cover',
                    }}
                />
            </Box>

            {/* content 6 */}
            <Box sx={{ bgcolor: 'orange', py: 5, px: 10, display: 'flex', justifyContent: 'center', }}>
                <Grid container spacing={2} justifyContent="space-between">
                    {[
                        { img: img8, count: 2348, label: 'Total Campaign' },
                        { img: img9, count: 1748, label: 'Satisfied Donors' },
                        { img: img10, count: 4287, label: 'Fund Raised' },
                        { img: img11, count: 1294, label: 'Happy Volunteers' },
                    ].map((item, index) => (
                        <Grid item key={index} xs={12} sm={3}  >
                            <Box
                                sx={{
                                    bgcolor: 'white',
                                    borderRadius: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    p: 2,
                                    boxShadow: 3,
                                }}
                            >
                                <img src={item.img} height={60} width={60} alt={item.label} />
                                <Typography fontWeight="700" color="black" sx={{ mt: 1 }}>
                                    {item.count}
                                </Typography>
                                <Typography fontWeight="bold" color="black">
                                    {item.label}
                                </Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>

            <Box
                sx={{
                    color: 'black', textAlign: 'center', fontSize: { xs: 25, sm: 30 }, fontWeight: 'bold', my: 5
                }} >
                <Box component="span" sx={{ display: 'block' }}>
                    Our <span style={{ color: '#FFA600' }}> Images {''}</span>
                </Box>
            </Box>


            {/* content 7 */}
            <Box sx={{ mx: 5 }}>
                <Grid
                    container
                    spacing={2}
                    sx={{
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        my: 5,
                    }}
                >
                    {[img12, img13, img14, img18, img16, img17, img15, img19,].map((img, index) => (
                        <Grid
                            item
                            xs={gridColumns} sm={1} md={3} lg={3}
                            key={index}
                        >
                            <img
                                src={img}
                                height={{ sx: '150', sm: '200' }}
                                width="100%"
                                alt="Background"
                                style={{
                                    borderRadius: 14,
                                    borderStyle: 'solid',
                                    borderWidth: 2,
                                    borderColor: 'black', objectFit: 'fill',
                                }}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    );
};

export default Home;
