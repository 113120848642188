import React from "react";
import Header from "./Assests/Components/Header";
import Footer from "./Assests/Components/Footer";
import Home from "./Assests/Components/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ContactUs from "./Assests/Components/ContactUs";
import Orgnisation from "./Assests/Components/Orgnisation";
import DonateUs from "./Assests/Components/DonateUs";
import WorkingArea from "./Assests/Components/WorkingArea";
import Event from "./Assests/Components/Event";
import LoginSignup from "./Assests/Components/LoginSignup";

function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Orgnisation" element={<Orgnisation />} />
          <Route path="/WorkingArea" element={<WorkingArea />} />
          <Route path="/Event" element={<Event />} />
          <Route path="/DonateUs" element={<DonateUs />} />
          <Route path="/Contact" element={<ContactUs />} />
          <Route path="/LoginSignup" element={<LoginSignup />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
